<template>
    <CRow>
        <CCol col="12" xl="12">
            <CCol sm="12" class="mt-2">
                <CRow>                    
                    <CCol sm="4">
                        <CSelect
                            :label="$t('label.country')"
                            :options="optionsListPaises"
                            :disabled="apiStateLoading"
                            :horizontal="{label:'col-sm-12 col-lg-auto',input:'col-sm-12 col-lg-8'}"
                            addLabelClasses="text-right"
                            :value.sync="$store.state.ubicacion.filtroPais"
                            @change="$store.commit('ubicacion/mutationfiltroPais', $event.target.value)"
                        />                                                    
                    </CCol>
                    <CCol sm="4">
                        <CSelect
                            :label="$t('label.state')"
                            :options="optionsListEstado"
                            :horizontal="{label:'col-sm-12 col-lg-auto',input:'col-sm-12 col-lg-8'}"
                            addLabelClasses="text-right"
                            :value.sync="$store.state.ubicacion.filtroEstado"
                            @change="$store.commit('ubicacion/mutationfiltroEstado', $event.target.value)"
                            :disabled="apiStateLoading"
                        />                                                      
                    </CCol>
                    <CCol sm="4" class="d-flex justify-content-end">
                        <div>
                            <CButton
                                color="add"
                                @click="activarModal()"
                                v-c-tooltip="{placement:'top-end',content:$t('label.municipality')}"
                            >
                                <CIcon name="cil-playlist-add"/>&nbsp; {{$t('label.nuevo')}}
                            </CButton>
                        </div>
                    </CCol>                    
                </CRow>
                <dataTableExtended
     class="align-center-row-datatable"
                    hover
                    sorter
                    small
                    column-filter
                    :table-filter="tableText.tableFilterText"
                    :items-per-page-select="tableText.itemsPerPageText"
                    :items="formatedItems"
                    :fields="fields"
                    :loading="loading"
                    :noItemsView="tableText.noItemsViewText"
                    :items-per-page="5"
                    pagination
                    @row-clicked="tabChanged"
                     
                >
                    <template #loading>
                        <loading/>
                    </template> 
                    <template #MunicipalityId="{ index }">
                        <td class="text-center">
                            {{index+1}}
                        </td>
                    </template>
                    <template #Status="{item}">
                        <td class="text-center">
                            <CBadge v-if="item.status" color="success">
                                {{ $t('label.'+item.Status) }}
                            </CBadge>
                            <CBadge v-if="!item.status" color="danger">
                                {{ $t('label.'+item.Status) }}
                            </CBadge>
                        </td>
                    </template>
                    <template #acciones="{item, index}">
                        <td class="center-cell">
                            <CButton
                                color="edit"
                                square
                                size="sm"
                                class="mr-1"
                                v-c-tooltip="{
                                    content: $t('label.edit')+$t('label.municipality'),
                                    placement: 'top-end'
                                }"
                                @click="updateModal(item, index)"
                            >
                                <CIcon name="pencil"/>
                            </CButton>
                        </td>
                    </template>
                </dataTableExtended>
            </CCol>
        </CCol>
    </CRow>
</template>

<script>
    import ENUM from '@/_store/enum';
    import General from "@/_mixins/general";
    import { mapState,mapMutations } from 'vuex';
    
    const dataMunicipio = [];
    
    function fields(){
        return [
            { key: 'MunicipalityId',label: '#',filter :false,_classes:'center-cell text-center',_style: 'width:5%;', },
            /*{ key: 'NbPais',label: 'NOMBRE DEL PAÍS', _style:'min-width:100px;' },
            { key: 'NbEstado',label: 'NOMBRE DEL ESTADO', _style:'min-width:100px;' },*/
            { key: 'MunicipalityName',label: this.$t('label.municipality'),_classes:'center-cell text-center' ,_style:'width:50%;' },
            { key: 'UltimoUsuario', label: this.$t('label.user'),_classes:'center-cell text-center' ,_style: 'width:14%;' },
            { key: 'FormatedDate', label:this.$t('label.date'),_classes:'center-cell text-center', _style: 'width:14%;'},
            { key: 'Status',label: this.$t('label.status'),_classes:'center-cell text-center',_style:'width:14%; text-align:center;' },
            { 
                key: 'acciones', 
                label: '', 
                _style: 'width45px', 
                sorter: false, 
                filter: false
            }
        ]
    }

    //data
    function data() {
        return {                       
            //MydataEstado:[],
            activePage: 1,
            warningModal:false,
        }
    }

    //computed
    function computedItems(){
        return this.$store.getters["ubicacion/myDataMunicipiotable"];
    }

    function formatedItems() {
        if(this.computedItems.length === 0)
            return []
    
        return this.computedItems.filter(item => {
            return item
        })
    }

    function apiStateLoading() {
        let carga = false;
        if(this.apiState === ENUM.LOADING || this.apiState === ENUM.INIT){
            carga = !carga;
        }
        return carga;
    }

    function optionsListPaises(){
        if(this.myDataPais.length === 0){
            return [{
                value: '', 
                label: this.$t('label.select')
            }];
        }else{
            const chart = [{
                value: '', 
                label: this.$t('label.select')
            }];
            this.myDataPais.map(function(e){
                if(e.FgActCountry){
                    chart.push({
                        value: e.CountryId, 
                        label: e.CountryName,
                    })
                }
            })
            return chart;
        }
    }

    function optionsListEstado(){
        if(this.myDataEstado.length === 0){
            return [{
                value: '', 
                label: this.$t('label.select')
            }];
        }else{
            const chart = [{
                value: '', 
                label: this.$t('label.select')
            }];
            this.myDataEstado.map(function(e){
                if(e.FgActState){
                    chart.push({
                        value: e.StateId, 
                        label: e.StateName,
                    })
                }
            })
            return chart;
        }
    }

    //methods
    function pageChange(val) {
        this.$router.push({ query: { page: val }});
    }
    function activarModal(){
        this.$store.commit('ubicacion/mutationModalMunicipio', true);
    }
    function updateModal(item,index){
        this.$store.commit('ubicacion/mutationModalMunicipio', true);
        this.$store.commit('ubicacion/asignarid', item.MunicipalityId);
    }
    function setRefresh(){
        let idEstado = this.estadoSelected;
        if(idEstado !== ""){
            this.$store.dispatch('ubicacion/getMunicipiolist', {idEstado});
        }else{
            this.$store.state.ubicacion.myDataMunicipio = [];
        }
    }
    function tabChanged(item, index, column, event){
        if(column !== 'acciones' && item.status === true){
            this.$store.state.ubicacion.filtroPais = item.CountryId;
            this.$store.state.ubicacion.filtroEstado = item.StateId;
            this.$store.state.ubicacion.filtroMunicipio = item.MunicipalityId;
            this.$store.state.ubicacion.tabIndex = 'tab4';
        }
    }

    //watch
    function handlerRoute() {
        return {
            immediate: true,
            handler (route) {
                if (route.query && route.query.page) {
                this.activePage = Number(route.query.page)
                }
            }
        };
    }
    /*function paisSelected(newQuestion,Oldquestion){
        if(newQuestion !== ""){
            this.estadoSelected = '';
            this.MydataEstado = [];
            this.loading = true;
            this.$store.state.ubicacion.myDataMunicipio = [];
            let idPais = newQuestion;
            UbicacionDataService.getEstadolist(idPais)
            .then(
                Estados => {
                    try{
                        if(Estados.status === 200){
                            this.loading = false;
                            this.MydataEstado = Estados.data.data;
                        }else{
                            this.loading = false;
                        }
                    }catch(error){
                        this.loading = false;
                        this.$store.commit('ubicacion/getMunicipiolistFailure', error)
                    }
                },
                error => {
                    this.loading = false;
                    this.$store.commit('ubicacion/getMunicipiolistFailure', error)
                }
            );
        }else{
            this.loading = false;
            this.$store.state.ubicacion.filtroEstado = '';
            this.MydataEstado = [];
            this.$store.state.ubicacion.myDataMunicipio = [];
        }
    }*/
    function busqueda(newQuestion,Oldquestion){
        if(newQuestion !== ""){
            let idEstado = newQuestion;
            this.$store.dispatch('ubicacion/getMunicipiolist', {idEstado});
        }else{
            this.$store.state.ubicacion.myDataMunicipio = [];
        }
    }

    export default {
        name: 'app-municipio',
        data,
        props: ['loading','Tab'],
        mixins: [General],
        watch: {
            $route: handlerRoute,
            filtroEstado: {
                deep:true,
                handler:busqueda
            },

            Tab:function (val){
                if(val == 2){
                    let idEstado = this.filtroEstado; 
                    if (this.$store.state.ubicacion.filtroPais && this.$store.state.ubicacion.filtroEstado) {
                        this.$store.dispatch('ubicacion/getMunicipiolist', {idEstado});
                    }else{
                        this.$store.state.ubicacion.myDataMunicipio = [];
                    }
                }
                
            },
        },
        methods: {
            pageChange,
            updateModal,
            activarModal,
            setRefresh,
            tabChanged
        },
        computed: {
            fields,      
            computedItems,
            formatedItems,
            apiStateLoading,            
            optionsListPaises,
            optionsListEstado,
            ...mapState({
                apiState: state => state.ubicacion.apiState,
                myDataPais: state => state.ubicacion.myDataPais,
                myDataEstado: state => state.ubicacion.myDataEstado,
                filtroPais: state => state.ubicacion.filtroPais,
                filtroEstado: state => state.ubicacion.filtroEstado
            })
        }
    }
</script>
<style lang="scss">
.img-index-imdg {
  width: 50px;
  height: 50px;
}
.center-cell {
  text-align: center;
}
 
</style>